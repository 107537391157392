<template>
  <div>
      
    <div class="row text-center">
        
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-3">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-link"></i><span>العقود</span>
          </div>
          <div class="overview-box-count">{{ contractsCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-4">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-id-card"></i><span>العملاء</span>
          </div>
          <div class="overview-box-count">{{ clientsCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-1">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-book"></i><span>اجمالى العقود</span>
          </div>
          <div class="overview-box-count">{{ contractsTotalCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-2">
          <div class="overview-box-title">
            <i class="pi pi-tags"></i><span>اجمالي التحصيلات</span>
          </div>
          <div class="overview-box-count">{{ paymentsCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contractsCount: 0,
      clientsCount: 0,
      contractsTotalCount: 0,
      paymentsCount: 0,
    };
  },

  methods: {
    getData() {},
    
  },
  created() {
    this.$http.post(`contracts/count`).then((res) => {
      this.contractsCount = res.data;
    });
    this.$http.post(`clients/count`).then((res) => {
      this.clientsCount = res.data;
    });
    this.$http.get(`contracts/contractsTotalCount`).then((res) => {
      this.contractsTotalCount = res.data.reduce((val, item) => {
        return val + item.total;
      }, 0);
    });
    this.$http.get(`payments/countNotNull`).then((res) => {
      this.paymentsCount = res.data.reduce((val, item) => {
        return val + item.price;
      }, 0);
    });
  },
};
</script>

<style>
.overview-box {
  padding: 15px;
  color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;
}
.overview-box .overview-box-title {
  font-weight: bold;
  width: 100%;
}
.overview-box .overview-box-title i {
  vertical-align: middle;
  font-size: 20px;
}
.overview-box .overview-box-title span {
  margin-right: 0.5em;
  vertical-align: middle;
}
.overview-box .overview-box-count {
  color: #ffffff;
  font-size: 24px;
  width: 100%;
  display: block;
  padding: 5px 0;
}
.overview-box.overview-box-1 {
  background-color: #007bff;
  border: solid 1px #007bff;
  color: #ffffff;
}
.overview-box.overview-box-2 {
  background-color: #28a745;
  border: solid 1px #28a745;
  color: #ffffff;
}
.overview-box.overview-box-3 {
  background-color: #fd7e14;
  border: solid 1px #fd7e14;
  color: #ffffff;
}
.overview-box.overview-box-4 {
  background-color: #6f42c1;
  border: solid 1px #6f42c1;
  color: #ffffff;
}
</style>
